import React, { useState, useEffect, useCallback } from 'react'
import LinkButton from '../../components/linkButton'
import Button from '../../components/button'
import CopyInput from '../../components/copyInput'
import Icon from '../../components/icon'
import Alert from '../../components/alert'
import { useApi } from '../../hooks/useApi'
import {
	faPlus,
	faSave,
	faTimes,
	faTrashAlt,
	faExclamationCircle
} from '@fortawesome/free-solid-svg-icons'
import AccountLayout from './components/accountSettingsLayout'
import tw from 'twin.macro'

const APIKeys = ({...props}) => {

	const { callApi } = useApi();
	const [apiKeys, setApiKeys] = useState([]);
	const [showKeyForm, setShowKeyForm] = useState(false);
	const [error, setError] = useState();

	const getKeys = useCallback(() => {
		return callApi({
			method: 'get',
			namespace: 'api_keys'
		})
			.then((result) => {
				setApiKeys(result.data.apiKeys);
			})
			.catch((err) => {
				if (err.response && err.response.data) {
					setError(err.response.data);
				}
				else {
					// console.log(err)
					setError(err);
				}
			});
	}, [callApi]);

	useEffect(() => {
		getKeys();
	}, [getKeys]);

	const [keyName, setKeyName] = useState();
	const [newKey, setNewKey] = useState();
	const [selectedKey, setSelectedKey] = useState();

	const saveKey = () => {
		callApi({
			method: selectedKey ? 'put' : 'post',
			namespace: 'api_keys' + (selectedKey ? `/${selectedKey.id}` : ''),
			payload: {
				name: keyName
			}
		})
			.then((result) => {
				if (!selectedKey) {
					setNewKey(result.data);
				}
				setShowKeyForm(false);
				setSelectedKey(null);
				getKeys();
			})
			.catch((err) => {
				if (err.response && err.response.data) {
					setError(err.response.data);
				}
				else {
					console.log(err);
					setError(err);
				}
			});
	};

	const editKey = (event) => {
		event.preventDefault();
		const key = apiKeys.find(k => k.id === event.target.dataset.id);
		setSelectedKey(key);
		setKeyName(key.name);
		setShowKeyForm(true);
	};

	const deleteKey = (event) => {
		event.preventDefault();
		callApi({
			method: 'delete',
			namespace: `api_keys/${selectedKey.id}`
		})
			.then((result) => {
				setShowKeyForm(false);
				setSelectedKey(null);
				setShowConfirmDelete(false);
				getKeys();
			})
			.catch((err) => {
				if (err.response && err.response.data) {
					setError(err.response.data);
				}
				else {
					console.log(err);
					setError(err);
				}
			});
	};

	const [showConfirmDelete, setShowConfirmDelete] = useState(false);

	const keyForm = <div>
		<label htmlFor="name">Key Name</label>
		<input name="name" maxLength="100" value={keyName} onChange={(e) => { setKeyName(e.target.value); }} placeholder="Descriptive key name"></input>
		{showConfirmDelete && <p tw="text-red mb-2">Are you sure you wish to delete this key? This action cannot be undone.</p>}
		<div tw="flex flex-col md:flex-row-reverse mt-4">
			{showConfirmDelete && <>
				<Button tw="mb-2 md:mb-0 md:ml-2" special="true" icon={faTrashAlt} onClick={deleteKey}>Confirm Delete</Button>
				<Button icon={faTimes} onClick={() => { setShowConfirmDelete(false); }}>Cancel</Button>
			</>}
			{!showConfirmDelete && <>
				<Button tw="mb-2 md:mb-0 md:ml-2" special="true" icon={faSave} onClick={() => saveKey()}>Save Key</Button>
				{selectedKey && <Button tw="mb-2 md:mb-0 md:ml-2" special="true" icon={faTrashAlt} onClick={() => setShowConfirmDelete(true)}>Delete Key</Button>}
				<Button icon={faTimes} onClick={() => { setSelectedKey(null); setShowKeyForm(false); }}>Cancel</Button>
			</>}
		</div>
	</div>;

	const keyList = apiKeys && apiKeys.length > 0 ?
		<div tw="my-4">
			<div tw="hidden md:flex font-semibold flex-col md:flex-row">
				<div tw="flex-1 p-2">Name</div>
				<div tw="flex-1 p-2">Prefix</div>
				<div tw="flex-1 p-2">Created</div>
				<div tw="flex-1 p-2">Modified</div>
			</div>
			{apiKeys.map(key => <div key={key.id} tw="grid grid-cols-12 md:flex flex-row p-4 md:p-0 bg-gray-100 mb-4 rounded-md">
					<div tw="col-span-12 flex-1 mb-4 md:m-0 md:p-2 flex md:whitespace-normal whitespace-nowrap truncate">
						<LinkButton tw="text-left" data-id={key.id} onClick={editKey}>{key.name}</LinkButton>
					</div>
					<div tw="col-span-4 sm:col-span-3 md:hidden mr-2">Prefix</div>
					<div tw="col-span-8 sm:col-span-9 md:p-2  flex-1">{key.prefix}</div>
					<div tw="col-span-4 sm:col-span-3 md:hidden mr-2">Created</div>
					<div tw="col-span-8 sm:col-span-9 md:p-2  flex-1">{new Date(key.created).toLocaleString()}</div>
					<div tw="col-span-4 sm:col-span-3 md:hidden mr-2">Modified</div>
					<div tw="col-span-8 sm:col-span-9 md:p-2  flex-1">{new Date(key.modified).toLocaleString()}</div>
			</div>
			)}
		</div>
		: <div tw="my-4">You have not yet created any API keys.</div>;

	const displayKey = newKey && <div>
		<h2>New API Key Details</h2>
		<Alert>Please record the information below and save it securely. This is the only time the API key will be displayed. After you navigate away from this page, the API key cannot be retrieved or restored.</Alert>
		<div tw="mt-8">
			<CopyInput value={`${newKey.prefix}.${newKey.key}`}/>
		</div>
		<div tw="grid grid-cols-12 gap-4 my-4">		
			<div tw="col-span-2 font-semibold">Name</div>
			<div tw="col-span-10">{newKey.name}</div>
			<div tw="col-span-2 font-semibold">Prefix</div>
			<div tw="col-span-10">{newKey.prefix}</div>
			<div tw="col-span-2 font-semibold">Created</div>
			<div tw="col-span-10">{new Date(newKey.created).toLocaleString()}</div>
		</div>
		<div tw="flex flex-col md:flex-row-reverse mt-4">
			<Button tw="my-4" special icon={faTimes} onClick={(e) => { setNewKey(null); setShowKeyForm(false); }}>Close</Button>
		</div>
	</div>;

	return <AccountLayout tab="apikeys" {...props}>
		<div>
			{!(showKeyForm || newKey) && keyList}
			{showKeyForm && !newKey && keyForm}
			{newKey && displayKey}
			<div tw="flex flex-col md:flex-row mt-4">
				{!(showKeyForm || newKey) && <Button tw="my-4" special icon={faPlus} onClick={(event) => { setKeyName(''); setShowKeyForm(true); }}>Create New API Key</Button>}
			</div>
			{error && <div tw="my-4!" className="error">{error.message || 'An error occurred.'}</div>}
		</div>
	</AccountLayout>
};

export default APIKeys
